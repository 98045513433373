// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-post-article-js": () => import("./../../../src/components/postArticle.js" /* webpackChunkName: "component---src-components-post-article-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lasting-power-of-attorney-js": () => import("./../../../src/pages/lasting-power-of-attorney.js" /* webpackChunkName: "component---src-pages-lasting-power-of-attorney-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-property-ownership-js": () => import("./../../../src/pages/property-ownership.js" /* webpackChunkName: "component---src-pages-property-ownership-js" */),
  "component---src-pages-trusts-js": () => import("./../../../src/pages/trusts.js" /* webpackChunkName: "component---src-pages-trusts-js" */),
  "component---src-pages-wills-js": () => import("./../../../src/pages/wills.js" /* webpackChunkName: "component---src-pages-wills-js" */)
}

